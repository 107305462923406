<template>
  <div>
    <page-toolbar title="Edit Booking Material">
      <template slot="action">
        <v-btn :loading="loading" v-if="detail.header.status == 'open'" text @click="updateData()">Save</v-btn>
      </template>
    </page-toolbar>
    <v-container>
      <page-title title="Booking Material">
        <template slot="action">
          <v-btn color="light" class="mx-1" @click="goBack()">Back</v-btn>
          <v-btn color="primary" :loading="loading" class="mx-1" @click="updateData()">Save</v-btn>
        </template>
      </page-title>

      <v-row v-if="loading == false && typeof detail.header.status !== 'undefined' && detail.header.status != 'open'">
        <v-col>
          <v-alert color="info" dark>
            <p>Document status must be open! <a href="javascript:;" @click="$router.go(-1)">Back</a></p>
          </v-alert>
        </v-col>
      </v-row>

      <v-row v-if="notFound == true && typeof detail.header.status === 'undefined'">
        <v-col>
          <v-alert color="warning" dark>
            <p>Document status must be open! <a href="javascript:;" @click="$router.go(-1)">Back</a></p>
          </v-alert>
        </v-col>
      </v-row>

      <v-row v-if="notFound == false">
        <v-col cols="12">
          <!-- General -->
          <card-expansion title="General" class="mb-3">
            <v-row>
              <v-col class="py-0" cols="12" md="6">
                <v-text-field dense disabled v-model="detail.header.document_no_" placeholder="Doc No." label="Doc No." readonly filled outlined hide-details class="mb-2"></v-text-field>
              </v-col>
              <v-col class="py-0" cols="12" md="6">
                <v-text-field type="date" dense disabled v-model="detail.header.document_date" placeholder="Doc. Date" label="Doc. Date" readonly filled outlined hide-details class="mb-2"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0" cols="12" md="6">
                <v-text-field dense v-model="detail.header.customer_no_" readonly disabled filled placeholder="Customer No." label="Customer No." outlined hide-details class="mb-2"></v-text-field>
              </v-col>
              <v-col class="py-0" cols="12" md="6">
                <v-text-field dense v-model="detail.header.campaign_no_" readonly disabled filled placeholder="Campaign No." label="Campaign No." outlined hide-details class="mb-2"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0" cols="12" md="6">
                <v-text-field dense v-model="detail.header.area_code" readonly disabled filled placeholder="Area Code" label="Area Code" outlined hide-details class="mb-2"></v-text-field>
              </v-col>
              <v-col class="py-0" cols="12" md="6">
                <v-text-field dense v-model="detail.header.kom_no_" readonly disabled filled placeholder="KOM No." label="KOM No." outlined hide-details class="mb-2"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0" cols="12" md="6">
                <v-text-field type="date" v-model="detail.header.kom_date" readonly disabled filled placeholder="KOM Date" label="KOM Date" dense outlined hide-details class="mb-2"></v-text-field>
              </v-col>
              <v-col class="py-0" cols="12" md="6">
                <v-text-field dense v-model="detail.header.kom_status" readonly disabled filled placeholder="KOM Status" label="KOM Status" outlined hide-details class="mb-2"></v-text-field>
              </v-col>
            </v-row>
          </card-expansion>
          <!-- Items -->
          <card-expansion title="Items" class="mb-3">
            <v-row>
              <v-col class="py-0" cols="12" md="9">
                <v-btn outlined color="primary" class="mx-1" :loading="loading_items" @click="generate()">Generate</v-btn>
              </v-col>
              <v-col class="py-0" cols="12" md="3">
                <v-text-field outlined label="Search" placeholder="Search" v-model="datatable_items.search" dense hide-details></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-card elevation="1" outlined>
                  <v-data-table class="mb-3 my-0" dense :loading="datatable_items.loading" :search="datatable_items.search" :loading-text="datatable_items.loadingText" :headers="datatable_items.columns" :items="detail.items" :hide-default-header="isMobile">
                    <template v-slot:[`item.item_no_`]="{ item }">
                      <a href="javascript:;" @click="showAllocation(item)">{{ item.item_no_ }}</a>
                    </template>
                    <template v-slot:[`item.quantity_allocation`]="{ item }">
                      <a href="javascript:;" @click="showAllocation(item)">{{ item.quantity_allocation }}</a>
                    </template>
                    <v-alert slot="no-results" :value="true" color="error" icon="warning">Your search for "{{ search }}" found no results.</v-alert>
                  </v-data-table>
                </v-card>
              </v-col>
            </v-row>
          </card-expansion>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="dialog" max-width="1200px" persistent>
      <v-card>
        <v-card-title>
          {{ selectedItem.item_no_ }} |
          {{ selectedItem.item_description }}
          <v-spacer></v-spacer>
          <v-btn icon @click="showAllocationPrev">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn icon @click="showAllocationNext">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
          <v-btn icon @click.stop="dialog = !dialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="mb-4"></v-divider>
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field dense disabled outlined hide-details class="mb-2" label="Quote No." v-model="selectedItem.quote_no_"></v-text-field>
              <v-text-field dense disabled outlined hide-details class="mb-2" label="Item No." v-model="selectedItem.item_no_"></v-text-field>
              <v-text-field dense disabled outlined hide-details class="mb-2" label="Item Description" v-model="selectedItem.item_description"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field dense disabled outlined hide-details class="mb-2" label="Demand" type="number" v-model="selectedItem.quantity_demand"></v-text-field>
              <v-text-field dense disabled outlined hide-details class="mb-2" label="Allocation" type="number" v-model="selectedItem.quantity_allocation"></v-text-field>
              <v-text-field dense disabled outlined hide-details class="mb-2" label="Balance" type="number" v-model="selectedItem.balance"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-card elevation="1" outlined>
                <v-data-table :loading="datatable_allocation.loading" :loading-text="datatable_allocation.loading_text" :items="datatable_allocation.data" :headers="datatable_allocation.columns">
                  <template v-slot:[`item.quantity_allocation`]="{ item }">
                    <v-text-field dense hide-details outlined type="number" v-model="item.quantity_allocation" :max="item.quantity_available"></v-text-field>
                  </template>
                  <template v-slot:[`item.action`]="{ item }">
                    <v-btn @click="removeAllocation(item)" outlined color="error">Remove</v-btn>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="primary" @click.stop="(dialogAddAllocation = !dialogAddAllocation), getAvailability()">Availability</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click.stop="dialog = !dialog">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogAddAllocation" max-width="600px" persistent>
      <v-card>
        <v-card-title>
          <span>Availability</span>
          <v-spacer></v-spacer>
          <v-text-field dense hide-details outlined placeholder="Search" v-model="datatable_availability.search"></v-text-field>
        </v-card-title>
        <v-divider></v-divider>
        <v-data-table dense :loading="datatable_availability.loading" :search="datatable_availability.search" :loading-text="datatable_availability.loading_text" :items="datatable_availability.data" :headers="datatable_availability.columns">
          <template v-slot:[`item.action`]="{ item }">
            <v-btn x-small @click="addAllocation(item)" outlined>Add</v-btn>
          </template>
        </v-data-table>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click.stop="dialogAddAllocation = !dialogAddAllocation">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      notFound: false,
      tab: null,

      dialog: null,
      dialogDate1: null,
      dialogDate2: null,

      detail: {
        header: {},
        quotes: [],
        items: [],
      },
      sales_quotes: [],
      sales_quote_lines: [],
      selected_quotes: [],

      items: [],

      loading_sales_quotes: false,
      loading_items: false,
      loading: null,

      elevations: [6, 12, 18],
      search: "",
      datatable_items: {
        search: "",
        loading: null,
        loadingText: "Loading... Please wait...",
        columns: [
          { text: "Quote No.", value: "quote_no_", class: "text-start" },
          { text: "Product Group", value: "product_group", class: "text-start" },
          { text: "Item No.", value: "item_no_", class: "text-start" },
          { text: "Description", value: "item_description", class: "text-start" },
          { text: "Weight", value: "weight", class: "text-start" },
          { text: "Quantity", value: "quantity_demand", class: "text-start" },
          { text: "Allocation", value: "quantity_allocation", class: "text-start" },
          { text: "Balance", value: "balance", class: "text-start" },
        ],
        data: [],
      },

      selectedItem: {},
      datatable_allocation: {
        loading: null,
        loadingText: "Loading... Please wait...",
        columns: [
          { text: "Source", value: null, class: "text-start" },
          { text: "Area Code", value: "area_code", class: "text-start" },
          { text: "Location", value: "location_code", class: "text-start" },
          { text: "Avalibility", value: "quantity_available", class: "text-start" },
          { text: "Cost", value: null, class: "text-start" },
          { text: "Allocation", value: "quantity_allocation", class: "text-start mw60px" },
          { text: "#", value: "action", class: "text-start mw60px" },
        ],
        data: [],
      },

      dialogQuote: null,
      selectedQuote: {},

      dialogAddAllocation: null,
      datatable_availability: {
        search: "",
        loading: null,
        loadingText: "Loading... Please wait...",
        columns: [
          { text: "Priority", value: "priority", class: "text-start" },
          { text: "Area Code", value: "area_code", class: "text-start" },
          { text: "Location", value: "location_code", class: "text-start" },
          { text: "Avalibility", value: "quantity_available", class: "text-start" },
          { text: "#", value: "action", class: "text-start mw60px" },
        ],
        data: [],
      },
    };
  },

  watch: {
    detail: {
      // eslint-disable-next-line no-unused-vars
      handler(n, o) {
        n.items.forEach((item) => {
          var a = 0;
          item.allocation.forEach((item2) => {
            a = parseFloat(a) + parseFloat(item2.quantity_allocation);
          });

          item.quantity_allocation = a;

          item.balance = item.quantity_demand - item.quantity_allocation;
        });
      },
      deep: true,
    },
  },

  methods: {
    async getDetail(id) {
      this.selected_quotes = [];

      var config = {
        cacheConfig: false,
        params: {
          id: id,
        },
      };
      this.showLoadingOverlay(true);
      this.rightbar = false;
      this.notFound = null;
      await this.$axios
        .get("bookingmaterial/detail", config)
        .then((res) => {
          var resData = res.data;
          if (resData.status == "success") {
            this.detail.header = resData.data.header;
            this.detail.quotes = resData.data.quotes;
            this.detail.items = resData.data.items;

            this.datatable_items.data = resData.data.items;
            this.notFound = false;

            this.detail.quotes.forEach((item) => {
              this.selected_quotes.push(item.quote_no_);
            });
          } else {
            this.showAlert(resData.status, resData.message);
          }
          this.rightbar = true;

          this.showLoadingOverlay(false);
        })
        .catch((error) => {
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
          this.rightbar = false;
          if (error.response.status == 404) {
            this.notFound = true;
          }
        });
    },
    async generate() {
      this.loading_items = true;
      this.datatable_items.loading = true;
      var formData = new FormData();
      formData.append("id", this.$route.query.id);
      await this.$axios
        .post("bookingmaterial/generate", formData)
        .then((res) => {
          var resData = res.data;
          if (resData.status == "success") {
            // console.log(resData.data.items);
            this.detail.items = resData.data.items;

            // console.log(this.detail.items);
          } else {
            this.showAlert(resData.status, resData.message);
          }
          this.datatable_items.loading = false;
          this.loading_items = false;

          // this.getDetail(this.$route.query.id);
        })
        .catch((error) => {
          this.axiosErrorHandler(error);
          this.datatable_items.loading = false;
          this.loading_items = false;
        });
    },
    async updateData() {
      // console.log(this.detail);
      // return;

      this.showLoadingOverlay(true);
      this.loading = true;
      var formData = new FormData();

      this.appendArray(formData, this.detail.header, "header");
      this.appendArray(formData, this.detail.items, "items");

      // var header = this.detail.header;
      // for (let _h in header) {
      //   formData.append("header[" + _h + "]", header[_h]);
      // }
      // var items = this.detail.items;
      // for (let _i in items) {
      //   let item = items[_i];
      //   for (let _k in item) {
      //     if (typeof item[_k] != "object") {
      //       formData.append("items[" + _i + "][" + _k + "]", item[_k]);
      //     } else {
      //       let allocations = item["allocation"];
      //       for (let _ii in allocations) {
      //         let allocation = allocations[_ii];
      //         for (let _kk in allocation) {
      //           formData.append("items[" + _i + "][" + _k + "][" + _ii + "][" + _kk + "]", allocation[_kk]);
      //         }
      //       }
      //     }
      //   }
      // }

      await this.$axios
        .post("bookingmaterial/update", formData)
        .then((res) => {
          var resData = res.data;
          if (resData.status == "success") {
            this.showAlert(resData.status, resData.message);
          } else {
            this.showAlert(resData.status, resData.message);
          }
          this.loading = false;
          this.showLoadingOverlay(false);

          this.getDetail(this.$route.query.id);
        })
        .catch((error) => {
          this.axiosErrorHandler(error);
          this.showLoadingOverlay(false);
          this.loading = false;
        });
    },
    showAllocation(item) {
      this.selectedItem = item;
      this.dialog = true;
      this.datatable_allocation.data = item.allocation;
    },
    showAllocationPrev() {
      var i = this.detail.items.indexOf(this.selectedItem);
      if (typeof this.detail.items[i - 1] !== "undefined") {
        this.showAllocation(this.detail.items[i - 1]);
      }
    },
    showAllocationNext() {
      var i = this.detail.items.indexOf(this.selectedItem);
      if (typeof this.detail.items[i + 1] !== "undefined") {
        this.showAllocation(this.detail.items[i + 1]);
      }
    },
    async getAvailability() {
      this.datatable_availability.loading = true;
      this.datatable_availability.data = [];
      var config = {
        cacheConfig: false,
        params: {
          source_area_code: this.detail.header.area_code,
          item_no_: this.selectedItem.item_no_,
        },
      };
      await this.$axios
        .get("bookingmaterial/get-availability", config)
        .then((res) => {
          var resData = res.data;
          if (resData.status == "success") {
            this.datatable_availability.data = resData.data.results;
          } else {
            this.showAlert(resData.status, resData.message);
          }
          this.datatable_availability.loading = false;
        })
        .catch((error) => {
          this.datatable_availability.loading = false;
          this.axiosErrorHandler(error);
        });
    },
    addAllocation(item) {
      var i = this.detail.items.indexOf(this.selectedItem);

      var r = {
        id: 0,
        booking_material_header_id: this.selectedItem.booking_material_header_id,
        quote_no_: this.selectedItem.quote_no_,
        source_area_code: this.detail.header.area_code,
        area_code: item.area_code,
        location_code: item.location_code,
        item_no_: this.selectedItem.item_no_,
        item_description: this.selectedItem.item_description,
        weight: this.selectedItem.weight,
        generate_date: null,
        quantity_available: item.quantity_available,
        quantity_allocation: 0,
        total_weight: 0,
      };

      this.detail.items[i].allocation.push(r);

      this.dialogAddAllocation = !this.dialogAddAllocation;
    },
    removeAllocation(item) {
      var i = this.detail.items.indexOf(this.selectedItem);
      var ii = this.detail.items[i].allocation.indexOf(item);
      this.detail.items[i].allocation.splice(ii, 1);
    },
  },

  mounted() {
    this.requiredLogin();
    this.modulePermission("bookingmaterial", "modify", true);

    this.getDetail(this.$route.query.id);
  },
};
</script>

<style>
.mw60px {
  width: 160px !important;
}
</style>
